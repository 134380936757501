import React from 'react';

function LearningResources() {
  return (
    <div>
      <h1>Learning Resources</h1>
    </div>
  );
}

export default LearningResources;
